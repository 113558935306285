import React from 'react';
import { api, CopyRightFooter } from '../../utils';
import { LoginForm } from '../loginForm/login';
import styles from './index.less';

if (!localStorage.browserCode) {
  const agent = navigator.userAgent.toLowerCase();
  const regStrChrome = /chrome\/[\d.]+/gi;
  if (agent.indexOf('chrome') > 0) {
    const chromeCode = agent.match(regStrChrome);
    localStorage.browserCode = chromeCode[0];
  }
}

export default class GalleryLogin extends React.Component<{ store?; }> {
  render(): JSX.Element {
    return (

      <div
        className={styles.login}
      >
        <div
          className={styles.contentWrap}
        >
          <div className={styles.galleryBgs}>
            <img
              alt=""
              className={styles.galleryBgTop}
              src={`${api.oss}/images/galleryBgTop.png`}
            />
            <img
              alt=""
              className={styles.galleryBgMid}
              src={`${api.oss}/images/galleryBgMid.png`}
            />
            <img
              alt=""
              className={styles.galleryBgBottom}
              src={`${api.oss}/images/galleryBgBottom.png`}
            />
            <img
              alt=""
              className={styles.galleryBgTxt}
              src={`${api.oss}/images/galleryBgMidTxt.png`}
            />
          </div>
          <div className={styles.content}>
            <LoginForm
              accountType={3}
              changePasswordPath="/egenie-ts-iac/findPassword"
              loginPath="/egenie-ts-iac/galleryLogin"
              sysType="pc_gallery"
              tenantModule="100023,100027"
            />
          </div>
        </div>

        <CopyRightFooter/>
      </div>
    );
  }
}
